import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import ColorWrapper from "./ColorWrapper"
import ExternalLink from "./ExternalLink"
import ProjectLogo from "./ProjectLogo"

const Wrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
`

const ProjectWrapper = styled.li`
  padding: 1rem;
  border: 1px solid gray;
  display: flex;
  flex-direction: column;
`

const ProjectTitle = styled.h3`
  padding: 0;
  margin: 0 0 1rem 0;
  text-align: center;
  display: block;
`

const ProjectFooter = styled.div`
  align-self: flex-end;
  width: 100%;
`

const Links = styled.div`
  margin-top: 1rem;
  > * {
    display: inline-block;
    margin-right: 1rem;
  }
`

const Project = ({
  logoSrc,
  logoData,
  title,
  description,
  url,
  slug,
  color,
}) => (
  <ProjectWrapper>
    <ProjectTitle>
      <ColorWrapper color={color}>
        <Link to={slug}>{title}</Link>
      </ColorWrapper>
    </ProjectTitle>
    <ProjectLogo
      imageData={logoData}
      alt={`Logo of ${title}`}
      imageSrc={logoSrc}
      size={200}
    />
    <ProjectFooter>
      <p>{description}</p>
      <Links>
        <ColorWrapper>
          <Link to={slug}>Motivation</Link>
        </ColorWrapper>
        <ColorWrapper color={color}>
          <ExternalLink href={url}>{url}</ExternalLink>
        </ColorWrapper>
      </Links>
    </ProjectFooter>
  </ProjectWrapper>
)

const ProjectGrid = ({ projects }) => (
  <Wrapper>
    {projects.map((proj) => {
      const {
        node: {
          fields: { slug },
          frontmatter: {
            title,
            description,
            externalUrl,
            color,
            image: { publicURL, childImageSharp },
          },
        },
      } = proj
      return (
        <Project
          key={slug}
          logoSrc={publicURL}
          logoData={childImageSharp?.gatsbyImageData}
          slug={slug}
          url={externalUrl}
          title={title}
          description={description}
          color={color}
        />
      )
    })}
  </Wrapper>
)

export default ProjectGrid
