import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

const CircleImage = styled(GatsbyImage)`
  border-radius: 50%;
  border: 2px solid #c93380;
  padding: 5px;

  img {
    border-radius: 50%;
  }

  &:hover {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: wobble;
  }

  @keyframes wobble {
    from {
      transform: none;
    }

    15% {
      transform: translate3d(-21%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }

    30% {
      transform: translate3d(18%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }

    45% {
      transform: translate3d(-12%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }

    60% {
      transform: translate3d(6%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }

    75% {
      transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }

    to {
      transform: none;
    }
  }
`

const Avatar = ({ className }) => {
  const data = useStaticQuery(graphql`
    query AvatarQuery {
      avatar: file(absolutePath: { regex: "/profile-pic/" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 300, width: 300)
        }
      }
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  return (
    <CircleImage
      image={data.avatar.childImageSharp.gatsbyImageData}
      alt={`Picture of ${data.site.siteMetadata.author}`}
      className={className}
    />
  )
}

export default Avatar
