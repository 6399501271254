import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import Avatar from "../components/Avatar"
import ColorWrapper from "../components/ColorWrapper"
import SEO from "../components/seo"
import ProjectGrid from "../components/ProjectGrid"
import { HEADER_HEIGHT } from "../components/Header"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Block = styled.div`
  min-height: calc(100vh - ${HEADER_HEIGHT}rem);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  max-width: 960px;
  padding: 2rem;
`

const Heading = styled.h1`
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
  margin-top: 10vh;
  text-align: center;
  max-width: 800px;
`

const Subtitle = styled.h2`
  border-bottom: 3px solid var(--brand);
  display: inline-block;
  margin-bottom: 4rem;
`

const LatestPosts = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
`

const LatestPost = styled.li`
  margin-bottom: 3rem;
  h3 {
    margin: 0 0 0.5rem 0;
  }
  > span {
    display: block;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }
  p {
    margin: 0;
  }
`

const HomePage = ({ data }) => (
  <Layout title="Welcome">
    <SEO title="Hi 👋" />
    <Wrapper>
      <Block>
        <Avatar />
        <Heading>
          <span aria-label="wave" role="img">
            👋
          </span>{" "}
          Hi! I'm Tom; a husband, father and developer.
        </Heading>
      </Block>
      <Block>
        <Subtitle>blog</Subtitle>
        <LatestPosts>
          {data.posts.edges.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <LatestPost key={node.fields.slug}>
                <h3>
                  <ColorWrapper>
                    <Link to={node.fields.slug}>{title}</Link>
                  </ColorWrapper>
                </h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </LatestPost>
            )
          })}
        </LatestPosts>
        <p>
          <ColorWrapper>
            <Link to="/blog">&gt; View all posts</Link>
          </ColorWrapper>
        </p>
      </Block>
      <Block>
        <Subtitle>projects</Subtitle>
        <ProjectGrid projects={data.projects.edges} />
        <p>
          <ColorWrapper>
            <Link to="/projects">
              <span>&gt;</span> View all projects
            </Link>
          </ColorWrapper>
        </p>
      </Block>
    </Wrapper>
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  query {
    posts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 4
      filter: { fields: { slug: { regex: "/^/blog/" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            description
          }
        }
      }
    }
    projects: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
      filter: { fields: { slug: { regex: "/^/project/" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
            externalUrl
            color
            image {
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FIXED, height: 200)
              }
            }
          }
        }
      }
    }
  }
`
